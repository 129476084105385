<template>
    <div class="mx-3 mb-3">
        <v-skeleton-loader type="list-item-three-line" style="height:100%;" :loading="!loaded" transition="fade-transition">
            <v-card flat>
                <v-card-title>
                    Communities
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="newLocation" color="primary" style="text-transform:none">
                        <v-icon left>mdi-plus</v-icon>
                        Add new
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    These are all the communities using the system
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
                            <v-text-field v-model="filterLocations" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="locations" :search="filterLocations" :footer-props="paginationOptions"
                        @click:row="toLocation">
                        <template v-slot:item.adminContactPhone="{ item }">
                            {{ toPhone( item.adminContactPhone ) }}
                        </template>
                        <template v-slot:item.active="{ item }">
                            <v-chip small :class="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-skeleton-loader>

        <v-dialog v-model="locationDialog" persistent width="600">
            <v-card>
                <v-card-title>New location</v-card-title>
                <v-card-subtitle>Create new location</v-card-subtitle>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field v-model="locName" label="Name" :rules="[rules.required]"></v-text-field>
                        <v-row>
                            <v-col cols="7">
                                <v-select v-model="locPaymentFrequency" label="Payment frequency" :items="paymentFrequencies" 
                                    item-text="name" item-value="id" :rules="[rules.required]"></v-select>
                            </v-col>
                            <v-col cols="5">
                                <v-text-field v-model="locPaymentAmount" label="Payment amount" :rules="[rules.required, rules.isAmount]"></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-text-field v-model="locAddressLine1" label="Address Line 1" :rules="[rules.required]"></v-text-field>
                        <v-text-field v-model="locAddressLine2" label="Address Line 2"></v-text-field>

                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="locAddressCity" label="City" :rules="[rules.required]"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="locAddressState" label="State" :rules="[rules.required]" v-mask="'AA'"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="locAddressZipcode" label="Zipcode" :rules="[rules.required]" v-mask="'#####'"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-checkbox v-model="locActive" label="Active"></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeNewLocationDialog" style="text-transform:none;">Cancel</v-btn>
                    <v-btn depressed color="primary" @click="createNewLocation" style="text-transform:none;" :loading="creating">Create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mask } from 'vue-the-mask'
// import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    data: () => ({
        headers: [
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Admin', value: 'adminContactName', sortable: true },
            { text: 'Admin Phone', value: 'adminContactPhone', sortable: true },
            { text: 'City', value: 'city', sortable: true },
            { text: 'Units', value: 'totalUnits', sortable: true },
            { text: 'Active', value: 'active', sortable: true },
            // { text: 'Actions', value: 'more', sortable: false },
        ],
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loaded: false,
        filterLocations: '',

        locationDialog: false,
        creating: false,

        // new location
        locName             : '',
        locPaymentFrequency : 0,
        locPaymentAmount    : 0,
        locAddressLine1     : '',
        locAddressLine2     : '',
        locAddressCity      : '',
        locAddressState     : '',
        locAddressZipcode   : '',
        locActive           : false,

        rules               : {
            required: v => !!v || 'required',
            isAmount: v => _st.isNumeric( v ) || 'must be a valid amount',
        }
    }),
    computed: {
        ...mapGetters({
            locations           : 'locations/locations',
            paymentFrequencies  : 'paymentFrequency/paymentFrequency',
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadLocations           : 'locations/load',
            saveLocation            : 'lcoations/saveLocation',
            loadPaymentFrequencies  : 'paymentFrequency/load'
        }),
        async init() {

            try {
                this.loaded = false;
                await this.loadLocations();
                this.loaded = true;

                // load payment frequencies
                if( this.paymentFrequencies == null ) {
                    await this.loadPaymentFrequencies();
                }
            }
            catch(error) {
                this.loaded = true;
                this.mxShowMessage( error, 'error' );
            }
        },
        newLocation() {
            this.locationDialog = true;
        },
        toLocation(loc) {
            this.$router.push(`/su/locations/${loc.id}`);
        },
        toPhone( v ) {
            return _st.toPhoneNumber( v );
        },
        closeNewLocationDialog() {
            this.locationDialog         = false;
            this.locName                = '';
            this.locPaymentFrequency    = 0;
            this.locPaymentAmount       = 0;
            this.locAddressLine1        = '';
            this.locAddressLine2        = '';
            this.locAddressCity         = '';
            this.locAddressState        = '';
            this.locAddressZipcode      = '';
            this.locActive              = false;
            this.$refs.form.reset() 
        },
        async createNewLocation() {
            if( !this.$refs.form.validate() )
                return;

            try {
                this.creating = true;
                await this.saveLocation({
                    name                : this.locName,
                    paymentFrequency    : this.locPaymentFrequency,
                    paymentAmount       : this.locPaymentAmount,
                    addressLine1        : this.locAddressLine1,
                    addressLine2        : this.locAddressLine2,
                    addressCity         : this.locAddressCity,
                    addressState        : this.locAddressState,
                    addressZipcode      : this.locAddressZipcode,
                    active              : this.locActive
                });
                // let res = await api.post('/admin/location', {
                //     name                : this.locName,
                //     paymentFrequency    : this.locPaymentFrequency,
                //     paymentAmount       : this.locPaymentAmount,
                //     addressLine1        : this.locAddressLine1,
                //     addressLine2        : this.locAddressLine2,
                //     addressCity         : this.locAddressCity,
                //     addressState        : this.locAddressState,
                //     addressZipcode      : this.locAddressZipcode,
                //     active              : this.locActive
                // });
                this.creating = false;

                // if( res.data.status !== true ) {
                //     this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                //     return;
                // }

                // this.locations.push( res.data.data );
                this.init();
                this.closeNewLocationDialog();
                this.mxShowMessage('Location saved', 'success');
            }
            catch(error) {
                this.creating = false;
                this.mxShowMessage(error, 'error');
            }
        }
    },
    directives: {
        mask,
    },
}
</script>

<style lang="scss" scoped>

</style>